<template>
  <div class="autonym-step3-content">
    <div v-if="[-1, 1, 2].includes(status)" class="content">
      <img
        v-if="status === 1"
        class="img"
        src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_renzhen_success%403x.png"
      />
      <img
        v-else
        class="img"
        src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_renzhen_wating%403x.png"
      />
      <p class="pt-24 reason">{{ status === 1 ? '审核已通过!' : '已提交,请耐心等待审核结果' }}</p>
      <div class="pt-52">
        <Button v-if="status !== RealNameAuthStatus.Finish" @click="handleReload" plain type="primary">刷新</Button>
        <div class="d-flex flex-column" v-else>
          <Button @click="handleJump(PageEnum.BASE_MINE)" type="primary">前往个人中心</Button>
          <a
            v-if="userIdentityRef === IdentityEnum.CARRIER"
            class="mt-12 text-center fw-500"
            @click="handleJump(PageEnum.CARRIER_FLEET)"
            >查看我的车队</a
          >
        </div>
      </div>
    </div>
    <div v-else class="content">
      <img
        class="img"
        src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/
        chatu_renzheng_fail%403x.png"
      />
      <p class="pt-24 reason">{{ '认证失败' }}</p>
      <div class="p-16 mt-40 reject-reason-wrap">
        <div class="pb-24"><label class="text-muted text-28">常由于以下原因导致： </label></div>
        <div>
          <label class="pt-24 text-main leading-40 text-28">
            {{ reason }}
          </label>
        </div>
      </div>
      <p class="reason">原因：身份证号码不一致</p>
      <Button class="mt-52" @click="handleReset" type="primary">重新认证</Button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Button, DatetimePicker, Popup, Uploader, Toast } from 'vant';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { useUserVerifiedStore } from '@/store/verified';
import { useUserStore } from '@/store/user';
import { useRouter } from '@/utils/compsitionHack';
import { getRealNameInfoApi } from '@api/autonym';
import { RealNameAuthStatus } from '@/constants';
import { PageEnum } from '@/enums/pageEnum';
import { apiCreateCarrier } from '@/api/carrier';
import { IdentityEnum } from '@/enums/identityEnum';
import ClientApp from '@/client/index';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    Button,
    DatetimePicker,
    Popup,
    Uploader
  },
  setup() {
    const userStore = useUserStore();
    const carrierId = ref();
    const useVertifiedStore = useUserVerifiedStore();
    const userIdentityRef = computed(() => userStore.identity);
    const router = useRouter();

    const next = ref(false);

    // 认证状态
    const status = ref(useVertifiedStore?.verifiedInfo?.authStatus || 0); // 该状态不需要响应store中的更新

    // 失败原因
    const reason = ref(useVertifiedStore?.verifiedInfo?.rejectReason || '');

    const handleJump = async item => {
      if (userIdentityRef.value === IdentityEnum.CARRIER && !userStore?.carrierInfo?.carrierId) {
        // 车队长身份需要调用创建车队接口成功之后才能跳转到个人中心
        try {
          const res = apiCreateCarrier();
          if (res.data) {
            router.replace(item);
          }
        } catch (err) {
          Toast.fail(err);
        }
      } else {
        router.replace(item);
      }
    };

    const handleReload = async () => {
      try {
        const res = await getRealNameInfoApi();
        const { data } = res;
        carrierId.value = data?.carrierInfoVO?.carrierId || null;
        ClientApp.refreshDriverInfo();
        // 已认证
        if (data) {
          status.value = data.authStatus;
          switch (data.authStatus) {
            // 认证失败
            case RealNameAuthStatus.Unauthenticated:
              break;
            // 认证通过
            case RealNameAuthStatus.Finish:
              // 跳转了之后清空缓存
              break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    const handleReset = () => {
      // TODO: 重新认证
      useVertifiedStore.updateInfo({
        step: 0
      });
    };
    return {
      PageEnum,
      IdentityEnum,
      RealNameAuthStatus,
      status,
      reason,
      next,
      userIdentityRef,
      formatToDateTime,
      handleJump,
      handleReload,
      handleReset
    };
  }
});
</script>
<style lang="less" scoped>
.autonym-step3-content {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .img {
      width: 0.86rem;
      height: 0.97rem;
      margin-top: 1.28rem;
    }
    .fail-title {
      color: #ff6061;
      font-size: 0.16rem;
      height: 0.16rem;
      line-height: 0.16rem;
      font-weight: 600;
    }
    .reason {
      margin-top: 0.08rem;
      height: 0.14rem;
      line-height: 0.14rem;
      color: #878787;
    }
  }

  .reject-reason-wrap {
    box-sizing: border-box;
    width: 654rpx;
    background: #f8f8f8;
  }
}
</style>
